// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import ShopOutlined from '@ant-design/icons/ShopOutlined';
import ShoppingOutlined from '@ant-design/icons/ShoppingOutlined';
import ShoppingCartOutlined from '@ant-design/icons/ShoppingCartOutlined';
import BarChartOutlined from '@ant-design/icons/BarChartOutlined'

export default {
  SmileOutlined,
ShopOutlined,
ShoppingOutlined,
ShoppingCartOutlined,
BarChartOutlined
}
    