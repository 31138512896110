// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/user",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/app/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "login",
        "path": "/user/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login' */'/app/src/pages/user/login'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/app/src/layouts/SecurityLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/app/src/layouts/BasicLayout'), loading: LoadingComponent}),
        "authority": [
          "admin",
          "user"
        ],
        "routes": [
          {
            "path": "/",
            "redirect": "/welcome",
            "exact": true
          },
          {
            "path": "/welcome",
            "name": "welcome",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/app/src/pages/Welcome'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "suppliers",
            "icon": "shop",
            "path": "/suppliers",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Suppliers' */'/app/src/pages/Suppliers'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "sellers",
            "icon": "shopping",
            "path": "/sellers",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Sellers' */'/app/src/pages/Sellers'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "orders",
            "icon": "shopping-cart",
            "path": "/orders",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders' */'/app/src/pages/Orders'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "insights",
            "icon": "bar-chart",
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/suppliers/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Supplier' */'/app/src/pages/Supplier'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/sellers/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Seller' */'/app/src/pages/Seller'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/products/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product' */'/app/src/pages/Product'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
