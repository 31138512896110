import {queryCurrent} from '@/services/user';
import {getPageQuery, isJson} from '@/utils/utils'
import {setAuthority} from "@/utils/authority";
import {signInUser, signOutUser} from "@/services/login";
import rest from "@/utils/rest";
import {formatMessage, history} from "umi";
import {message} from "antd";
import {stringify} from "querystring";

const user = localStorage.getItem('antd-pro-authority');

const UserModel = {
  namespace: 'user',
  state: {
    currentUser: isJson(user) ? JSON.parse(user) : {},
    isLoading: true,
  },
  effects: {
    *fetchCurrent(_, {call, put}) {
      const response = yield call(queryCurrent);
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
    },

    *fetchUpdate({payload}, {put}) {
      yield put({
        type: 'saveCurrentUser',
        payload,
      });
    },
    *login({payload}, {call, put}) {
      const response = yield call(signInUser, payload);
      if (response.status) {
        rest.apiAxios.defaults.headers.common.authorization = response.token;
        const responseApi = yield rest.get(`users/me`);
        const {isAdmin} = responseApi.data;

        if (isAdmin) {
          yield put({
            type: 'saveCurrentUser',
            payload: responseApi.data,
          });
          const urlParams = new URL(window.location.href);
          const params = getPageQuery();
          let {redirect} = params;

          if (redirect) {
            const redirectUrlParams = new URL(redirect);

            if (redirectUrlParams.origin === urlParams.origin) {
              redirect = redirect.substr(urlParams.origin.length);

              if (redirect.match(/^\/.*#/)) {
                redirect = redirect.substr(redirect.indexOf('#') + 1);
              }
            } else {
              window.location.href = '/';
              return;
            }
          }
          history.replace(redirect || '/');
        } else {
          message.error(formatMessage({id: 'login.error.admin'}));
        }
      } else {
        message.error(response.message);
      }
    },

    *logout(_, {call, put}) {
      const {redirect} = getPageQuery();
      yield call(signOutUser);
      yield put({
        type: 'saveCurrentUser',
        payload: {},
      });
      if (window.location.pathname !== '/user/login' && !redirect) {
        history.replace({
          pathname: '/user/login',
          search: stringify({
            redirect: window.location.href,
          }),
        });
      }
    },
  },
  reducers: {
    saveCurrentUser(state, action) {
      setAuthority(action.payload);
      return {...state, currentUser: action.payload || {}, isLoading: false};
    },
  },
};
export default UserModel;
