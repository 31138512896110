import { parse } from 'querystring';
import pathRegexp from 'path-to-regexp';

/* eslint-disable no-nested-ternary */
export const photoPlaceholder = '@/assets/img/placeholder.png';
// eslint-disable-next-line no-undef
const URL_IMAGES = AWS_IMAGES_S3;
const SORT_KEY_MAP = { ascend: 'ASC', descend: 'DESC' };

const IMG_SIZES = {
  // eslint-disable-next-line no-undef
  large: IMAGES_SIZE_LARGE,
  // eslint-disable-next-line no-undef
  medium: IMAGES_SIZE_MEDIUM,
  // eslint-disable-next-line no-undef
  small: IMAGES_SIZE_SMALL,
};

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = path => reg.test(path);
export const isAntDesignPro = () => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }

  return window.location.hostname === 'preview.pro.ant.design';
}; // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性

export const isAntDesignProOrDev = () => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
    return true;
  }

  return isAntDesignPro();
};
export const getPageQuery = () => parse(window.location.href.split('?')[1]);
/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */

export const getAuthorityFromRouter = (router = [], pathname) => {
  const authority = router.find(
    ({ routes, path = '/', target = '_self' }) =>
      (path && target !== '_blank' && pathRegexp(path).exec(pathname)) ||
      (routes && getAuthorityFromRouter(routes, pathname)),
  );
  if (authority) return authority;
  return undefined;
};

export const getRouteAuthority = (path, routeData) => {
  let authorities;
  routeData.forEach(route => {
    // match prefix
    if (pathRegexp(`${route.path}/(.*)`).test(`${path}/`)) {
      if (route.authority) {
        authorities = route.authority;
      } // exact match

      if (route.path === path) {
        authorities = route.authority || authorities;
      } // get children authority recursively

      if (route.routes) {
        authorities = getRouteAuthority(path, route.routes) || authorities;
      }
    }
  });
  return authorities;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getImgProductUrl = (originalUrl, size = 'large') => {
  const paths = originalUrl.split('/');
  const filename = paths[paths.length - 1];
  const px = IMG_SIZES[`${size.toLowerCase()}`];
  return `${URL_IMAGES}/product-images/${px}/${filename}`;
};

export const getMainImgProductUrl = (product, size = 'large') => {
  if (!product.images.length) {
    return photoPlaceholder;
  }


  if (['SHOPIFY', 'WOOCOMMERCE', 'SQUARESPACE'].includes(product.origin)) {
    if (typeof product.images[1] === 'undefined') {
      return product.images[0].url;
    } 
      return product.images[1].url;
    
  }
  if (typeof product.images[1] === 'undefined') {
    return getImgProductUrl(product.images[0].url, size);
  } 
    return getImgProductUrl(product.images[1].url, size);
  ;
}

export const convertSorterOptions = (sorter, defaultValue = {}) => {
  const newSorter = defaultValue;
  Object.keys(sorter).forEach((key) => {
    newSorter[key] = SORT_KEY_MAP[sorter[key]]
  })
  return newSorter;
}