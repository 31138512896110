// eslint-disable-next-line import/no-extraneous-dependencies
import firebase from 'firebase/app';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'firebase/auth';

const params = {
  // eslint-disable-next-line no-undef
  projectId: FIREBASE_PROJECT_ID,
  // eslint-disable-next-line no-undef
  apiKey: FIREBASE_API_KEY,
  // eslint-disable-next-line no-undef
  authDomain: FIREBASE_AUTH_DOMAIN,
  // eslint-disable-next-line no-undef
  databaseURL: FIREBASE_DATABASE_URL,
  // eslint-disable-next-line no-undef
  storageBucket: FIREBASE_STORAGE_BUCKET,
  // eslint-disable-next-line no-undef
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
};

firebase.initializeApp(params);

const auth = firebase.auth();
auth.useDeviceLanguage();

export { auth, firebase };
