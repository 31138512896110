import request from '@/utils/request';
import rest from '@/utils/rest';

export async function query() {
  return request('/api/users');
}

export async function queryCurrent() {
  try {
    const response = await rest.get(`users/me`);
    return response.data;
  } catch (e) {
    return {};
  }
}

export async function queryNotices() {
  return request('/api/notices');
}
