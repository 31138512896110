export default {
  'menu.welcome': 'Welcome',
  'menu.admin': 'Admin',
  'menu.home': 'Home',
  'menu.suppliers': 'Suppliers',
  'menu.sellers': 'Sellers',
  'menu.insights': 'Insights',
  'menu.orders': 'Orders',
  'menu.login': 'Login',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
};
