import { auth, firebase } from '@/utils/firebase';

export async function signInUser({ email, password }) {
  return auth
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() =>
      auth
        .signInWithEmailAndPassword(email, password)
        .then((res) => {
          return auth.currentUser.getIdToken(true).then((data) => ({
            ...res,
            token: data,
            status: true,
          }));
        })
        .catch((error) => ({
          ...error,
          status: false,
        })),
    )
    .catch(/* error */);
}

export async function signOutUser() {
  return auth
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() =>
      auth
        .signOut()
        .then((res) => ({
          ...res,
          status: true,
        }))
        .catch((error) => ({
          ...error,
          status: false,
        })),
    )
    .catch(/* error */);
}
