import axios from 'axios';
import { auth } from '@/utils/firebase';

const apiAxios = axios.create();

let subscribers = [];
let isFetchingNewAccessToken = false;

// eslint-disable-next-line no-undef
const urlBase = `${API_HOST}/api/`;

const resetTokenAndReattemptRequest = async (error) => {
  try {
    const retryOriginalRequest = new Promise((resolve) => {
      subscribers.push((token) => {
        const newError = error;
        newError.response.config.headers.authorization = token;
        resolve(axios(newError.response.config));
      });
    });
    if (!isFetchingNewAccessToken) {
      isFetchingNewAccessToken = true;
      const newToken = await auth.currentUser.getIdToken(true);
      apiAxios.defaults.headers.common.authorization = newToken;
      isFetchingNewAccessToken = false;
      subscribers.forEach((prevRequest) => prevRequest(newToken));
      subscribers = [];
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
};

apiAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      return resetTokenAndReattemptRequest(error);
    }
    return Promise.reject(error);
  },
);

const get = async (path, params) => apiAxios.get(urlBase + path, {params});

const post = (path, body) => apiAxios.post(urlBase + path, body);

// eslint-disable-next-line no-undef
const postBase = (path, body) => apiAxios.post(`${API_HOST}/${path}`, body);

const put = async (path, body) => apiAxios.put(urlBase + path, body);

const patch = async (path, body) => apiAxios.patch(urlBase + path, body);

const remove = async (path, body) => apiAxios.delete(urlBase + path, { data: body });

const head = async (path) => apiAxios.head(urlBase + path);

export default {
  get,
  post,
  postBase,
  put,
  remove,
  head,
  patch,
  apiAxios,
};
